/*
 * @Author: ken yang
 * @Date: 2022-10-08 14:06:46
 * @LastEditTime: 2023-03-17 13:42:07
 * @LastEditors: ken yang
 * @Description: 活动相关接口
 * @FilePath: /uexch_pc/src/api/activity.js
 */
import axios from '@/utils/request'

const queryHomeUrl = '/activity/newcomer/homepage/query'
const queryHome = async (params) => {
  const { data } = await axios.post(queryHomeUrl, params)
  return data.data
}

const getWoolPartyUserUrl = '/activity/getWoolPartyUser'
const getWoolPartyUser = async (params) => {
  const { data } = await axios.post(getWoolPartyUserUrl, params)
  return data.data
}

const myRewardUrl = '/activity/newcomer/my-reward'
const myReward = async (body = {}) => {
  const { data } = await axios.post(myRewardUrl, body)
  return data.data
}

const drawPrizeUrl = '/activity/newcomer/extraction-reward-amount'
const drawPrize = async (params) => {
  const { data } = await axios.post(drawPrizeUrl, params)
  return data.data
}

const questionUrl = '/activity/newActivityGlobalConfig/question'
const question = async (params = {}) => {
  const { data } = await axios.post(questionUrl, params)
  return data.data
}

const withdrawalUrl = '/activity/newcomer/withdrawal'
const withdrawal = async (params) => {
  const { data } = await axios.post(withdrawalUrl, params)
  return data.data
}

// 当前活动的信息
const currentActivityInfoUrl = '/activity/currentActivityInfo'
const currentActivityInfo = async (params) => {
  const { data } = await axios.post(currentActivityInfoUrl, params)
  return data.data
}

// 当前用户的信息
const userCurrentUrl = '/activity/user/currentActivityInfo'
const userCurrent = async (params) => {
  const { data } = await axios.post(userCurrentUrl, params)
  return data.data
}

// 用户提交截图
const userAuditUrl = '/activity/user/audit'
const userAudit = async (params) => {
  const { data } = await axios.post(userAuditUrl, params)
  return data.data
}

//  用户领奖
const receiveAwardUrl = '/activity/user/receiveAward'
const receiveAward = async (params) => {
  const { data } = await axios.post(receiveAwardUrl, params)
  return data.data
}

// 弹窗list
const checkPopUlr = '/activity/reward/check-pop'
const checkPop = async (params = {}) => {
  const { data } = await axios.post(checkPopUlr, params)
  return data.data
}

// 手动领取奖励
const forUserUrl = '/activity/reward/forUser'
const forUser = async (params = {}) => {
  const { data } = await axios.post(forUserUrl, params)
  return data.data
}

// 新人拉新活动
const popUpOrNotUrl = '/activity/newRegisterActivity/novice/rank/popUpOrNot'
const popUpOrNot = async (params = {}) => {
  const { data } = await axios.post(popUpOrNotUrl, params)
  return data.data
}

// 拉新活动领取奖励
const newRegisterActivitySubmitUrl = '/activity/user/newRegisterActivity/submit'
const newRegisterActivitySubmit = async (params = {}) => {
  const { data } = await axios.post(newRegisterActivitySubmitUrl, params)
  return data
}

const winningRecordUrl = '/activity/winning/record'
const winningRecord = async (params = {}) => {
  const { data } = await axios.post(winningRecordUrl, params)
  return data
}

const christmasApplyUrl = '/activity/christmas/apply'
const christmasApply = async (params = {}) => {
  const { data } = await axios.post(christmasApplyUrl, params)
  return data
}

const christmasDetailUrl = '/activity/christmas/detail'
const christmasDetail = async (params = {}) => {
  const { data } = await axios.post(christmasDetailUrl, params)
  return data
}

const TimesReceiveUrl = '/activity/christmas/timesReceive'
const timesReceive = async (params = {}) => {
  const { data } = await axios.post(TimesReceiveUrl, params)
  return data
}

const awardRecordUrl = '/activity/christmas/awardRecord'
const awardRecord = async (params = {}) => {
  const { data } = await axios.post(awardRecordUrl, params)
  return data.data
}

const christmasDrawUrl = '/activity/christmas/draw'
const christmasDraw = async (params = {}) => {
  const { data } = await axios.post(christmasDrawUrl, params)
  return data.data
}

const christmasInviteRecordUrl = '/activity/christmas/inviteRecord'
const christmasInviteRecord = async (params = {}) => {
  const { data } = await axios.post(christmasInviteRecordUrl, params)
  return data.data
}

const needLoginUrl = [userCurrentUrl, userAuditUrl, receiveAwardUrl, popUpOrNotUrl]

export default {
  needLoginUrl,
  myReward,
  getWoolPartyUser,
  queryHome,
  drawPrize,
  question,
  withdrawal,
  currentActivityInfo,
  userCurrent,
  userAudit,
  receiveAward,
  checkPop,
  forUser,
  popUpOrNot,
  newRegisterActivitySubmit,
  winningRecord,
  christmasApply,
  christmasDetail,
  timesReceive,
  awardRecord,
  christmasDraw,
  christmasInviteRecord
}
