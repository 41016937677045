<!--
 * @Author: ken yang
 * @Date: 2023-06-09 16:11:36
 * @LastEditTime: 2023-09-20 19:13:41
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/layout/appHeader/AppHeader.vue
-->
<template>
  <Row class="AppHeader bg-dw min-W c-t ">
    <Row class="f-1 flow-atow ">
      <Link to="/" class="pointer">
        <img
          v-if="(['/trade', '/futures','/majroShareholder','/christmas'].includes(route.path) && configStore.theme == 'dark')||['/christmas','/majroShareholder'].includes(route.path)"
          v-inmodule="['/home']"
          alt="BiFinance"
          class=" w-98 m-r-20"
          width="98"
          src="@/image/home/logo-dark.webp"
        >
        <img
          v-else
          v-inmodule="['/home']"
          alt="BiFinance"
          class=" m-r-20"
          width="98"
          src="@/image/home/logo.webp"
        >
      </Link>
      <el-menu
        class="nav-menu flow-atow"
        mode="horizontal"
        :ellipsis="false"
        :default-active="defaultActive"
        @select="selectMenu"
      >
        <el-menu-item
          id="menu-item-home"
          v-inmodule="['/home']"
          class="nav-item "
          test-key="to-home"
          index="home"
        >{{ $t('header.home') }}</el-menu-item>
        <el-menu-item id="menu-item-quotes" v-inmodule="['/quotes']" class="nav-item " index="quotes">
          <template #title>
            <el-dropdown
              class=""
              :teleported="true"
              popper-class="dropdown-no-arrow "
              @visible-change="(v) => quotesArrow = v"
            >
              <Row class="c-title-hover-primary c-hover-primary c-t">
                <span class="t-d font-14 bold m-r-8">{{ $t('header.quotes') }}</span>
                <IconSvg class="t-d font-8 transition pointer" :class="quotesArrow ? 'rotateZ-180' : ''" :name="'icon-IconDown_Menu'" />
              </Row>
              <template #dropdown>
                <Column class="w-min-280 p-lr-10" y="s">
                  <Row v-to="'/quotes/odds'" class="w-100b h-66 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c">
                    <IconSvg class="title  m-r-16" name="icon-a-jihui1" />
                    <Column>
                      <span class="label">{{ $t('trade.opportunities') }}</span>
                      <span class="label-sm c-info">{{ $t('trade.timelySeizeNewMarketOpportunities') }}</span>
                    </Column>
                  </Row>
                  <Row v-to="'/quotes'" class="w-100b h-66 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c">
                    <IconSvg class="title  m-r-16" name="icon-a-shichang1" />
                    <Column>
                      <span class="label">{{ $t('header.quotes') }}</span>
                      <span class="label-sm c-info">{{ $t('trade.viewLatestCryptoPricesVolume') }}</span>
                    </Column>
                  </Row>
                  <!-- <Column class="f-1" y="s">
                    <span class="c-info label-sm p-lr-14 m-b-5 font-w-400">{{ $t('form.other') }}</span>
                    <Row v-to="'/registerSendBTC'" class="w-100b h-50 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c">{{ $t('public.newbieActivity') }}</Row>
                    <Row class="w-100b h-50 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c" @click="() => toRouter('/rebate', { requireLogin: true, redirect: '/rebate' })">{{ $t("wallet.invateRebate") }}</Row>
                  </Column> -->
                </Column>
              </template>
            </el-dropdown>
          </template>

        </el-menu-item>

        <el-menu-item
          id="menu-item-c2c"
          v-inmodule="['/c2c']"
          class="nav-item "
          index="c2c"
        >{{ $t('public.buyCoins') }}</el-menu-item>
        <el-menu-item id="menu-item-trade" v-inmodule="['/trade']" class="nav-item " index="trade">{{ $t('header.spot') }}</el-menu-item>
        <!-- v-if="env.MODE!=='production'" -->
        <!-- S合约 -->
        <el-menu-item
          v-if="isS"
          id="menu-item-futures"
          v-inmodule="['/futures']"
          class="nav-item "
          index="futures"
        >
          <Row class="position-r">
            {{ $t('futures.yfutures') }}
            <img v-if="['/trade', '/futures','/majroShareholder'].includes(route.path) && configStore.theme == 'dark'" class="futures-beta" src="@/image/home/f-beta-dark.webp">
            <img v-else class="futures-beta" src="@/image/home/f-beta.webp">
          </Row>
        </el-menu-item>

        <el-menu-item
          id="menu-item-launchpad"
          v-inmodule="['/launchpad','/launchpad/subscriptionHistory','/launchpad/projectInfo']"
          class="nav-item "
          index="launchpad"
        >Launchpad</el-menu-item>
        <el-menu-item id="menu-item-financial" v-inmodule="['/financial']" class="nav-item " index="financial">{{ $t('header.financial') }}</el-menu-item>

        <!-- <el-menu-item
          id="menu-item-vote"
          class="nav-item"
          index="vote"
        >
          <Row class="position-r">
            <img class="w-22 h-13 position-a top-6 -right-15" src="@/image/hot.webp">
            <span>{{ $t('header.vote') }}</span>
          </Row>
        </el-menu-item> -->
        <el-menu-item
          id="menu-item-vote"
          class="nav-item"
          index="roulette"
        >
          <Row class="position-r">
            <img class="w-20 h-20 m-r-2" src="@/image/activity/christmas/christmas-icon.png">
            <span>{{ $t('activity.roulette') }}</span>
          </Row>
        </el-menu-item>
        <el-menu-item
          id="menu-item-activity"
          class="nav-item p-r-0"
          index="activity"
        >

          <template #title>
            <el-dropdown
              class=""
              :teleported="true"
              popper-class="dropdown-no-arrow "
              @visible-change="(v) => eventsArrow = v"
            >
              <Row class="c-title-hover-primary c-hover-primary c-t">
                <span class="t-d font-14 bold m-r-8">{{ $t('public.more') }}</span>
                <IconSvg class="t-d font-8 transition pointer" :class="eventsArrow ? 'rotateZ-180' : ''" :name="'icon-IconDown_Menu'" />
              </Row>

              <template #dropdown>
                <Row class="w-356 h-220 p-tb-28 p-lr-20" y="s">
                  <Column class="f-1" y="s">
                    <span class="c-info label-sm m-b-5 p-lr-14 font-w-400" y="c">{{ $t('public.products') }}</span>
                    <Row v-to="'/bft'" class="w-100b h-50 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c">{{ $t('bft.aboutBFT') }}</Row>
                  </Column>
                  <Column class="f-1" y="s">
                    <span class="c-info label-sm p-lr-14 m-b-5 font-w-400">{{ $t('form.other') }}</span>
                    <!-- <Row v-to="'/registerSendBTC'" class="w-100b h-50 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c">{{ $t('public.newbieActivity') }}</Row> -->
                    <Row class="w-100b h-50 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c" @click="() => toRouter('/rebate', { requireLogin: true, redirect: '/rebate' })">{{ $t("wallet.invateRebate") }}</Row>
                    <Row class="w-100b h-50 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c" @click="() => toRouter('/agentApplication')">{{ $t(`agent.partnerRecruitment`) }}<Row /></row>
                    <Row class="w-100b h-50 p-lr-14 pointer label font-w-400 bg-hover-line radius-4" y="c" @click="() => toRouter('/agentApplication')">{{ $t('header.vote') }}<Row /></row>
                  </Column>
                </Row>
              </template>
            </el-dropdown>
          </template>
        </el-menu-item>
        <el-menu-item
          id="menu-item-newcomerBenefits"
          class="nav-item"
          index="newcomerBenefits"
        >
          <Row class="p-lr-10 h-32 bg-warning-20 radius-4 position-r" x="c" y="c">
            <img class="w-20 h-22 position-a -top-9 -right-9" src="@/image/activity/incentives-2.webp" alt="">
            <span class="c-d label">{{ $t('activity.timeNewbieBenefits2') }}</span>
          </Row>
        </el-menu-item>
      </el-menu>
    </Row>
    <!-- 右边菜单 -->
    <Row class="right-box f-between f-y-center ">
      <Row v-if="!userStore.token">
        <div id="appHeader-to-login" v-to="'/login'" class="pointer m-r-10 login-hover">
          <div v-inmodule="['/login']" class="w-70 h-36 f-center font-14 bold login-hover">
            {{ $t(`form.login`) }}
          </div>
        </div>
        <Link id="appHeader-to-register" to="/register" class="pointer m-r-10">
          <div v-inmodule="['/register']" class=" p-lr-20 h-36 f-center font-14 bold bg-primary radius c-white">
            {{ $t(`form.register`) }}
          </div>
        </Link>
      </Row>

      <el-button v-if="userStore.token" type="primary" class="w-86 h-36 font-14 m-r-30" @click="obRecharge">
        <IconSvg name="icon-ICON_Deposit" class="font-16 m-r-6" />
        <span>{{ $t('wallet.recharge') }}</span>
      </el-button>

      <!-- 资产 -->
      <el-dropdown
        v-if="userStore.token"
        id="appHeader-to-wallet"
        :teleported="true"
        class="wallet c-title-hover-primary "
        style="{z-index: 20000;}"
        popper-class="dropdown-no-arrow "
        @visible-change="(v) => walletArrow = v"
      >
        <Row class="m-r-20 c-t wallet-active">
          <IconSvg class="title transition pointer font-18" name="icon-asset" />
          <Row class="pointer h-20 transition p-l-10  bold m-r-8 font-14" :style="{'white-space':'nowrap'}">{{ $t("userCenter.assets") }}</Row>
          <IconSvg class=" font-8 transition pointer m-l-6" :class="walletArrow ? 'rotateZ-180' : ''" :name="'icon-IconDown_Menu'" />
        </Row>
        <template #dropdown>
          <el-dropdown-menu x="c">
            <el-dropdown-item
              test-key="walletOverview"
              class=" appheader-dropdown-item bold"
              :class="{'bg-d':route.path.includes('walletOverview')}"
              @click="() => toRouter('/wallet/walletOverview')"
            >
              <span v-inmodule="['/wallet/walletOverview']">{{ $t("wallet.assetsOverview") }}</span>
            </el-dropdown-item>
            <!-- <el-dropdown-item
              test-key="profitLossAnalysis"
              class="appheader-dropdown-item bold "
              :class="{'bg-d':route.path.includes('profitLossAnalysis')}"
              @click="() => toRouter('/wallet/profitLossAnalysis')"
            >
              <span v-inmodule="['/wallet/profitLossAnalysis']">{{ $t("wallet.profitLossAnalysis") }}</span>
            </el-dropdown-item> -->
            <el-dropdown-item
              test-key="spotAccount"
              class="appheader-dropdown-item bold "
              :class="{'bg-d':route.path.includes('spotAccount')}"
              @click="() => toRouter('/wallet/spotAccount')"
            >
              <span v-inmodule="['/wallet/spotAccount']">{{ $t("wallet.spotAccount") }}</span>
            </el-dropdown-item>

            <el-dropdown-item
              test-key="futures"
              class=" appheader-dropdown-item bold "
              :class="{'bg-d':route.path == '/wallet/futures'}"
              @click="() => toRouter('/wallet/futures')"
            >
              <span v-inmodule="['/wallet/futures']">{{ $t("wallet.futures") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              test-key="financialAccount"
              class="appheader-dropdown-item bold "
              :class="{'bg-d':route.path.includes('financialAccount')}"
              @click="() => toRouter('/wallet/financialAccount')"
            >
              <span v-inmodule="['/wallet/financialAccount']">{{ $t("wallet.financialAccount") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              test-key="fiatAccount"
              class="appheader-dropdown-item bold "
              :class="{'bg-d':route.path.includes('fiatAccount')}"
              @click="() => toRouter('/wallet/fiatAccount')"
            >
              <span v-inmodule="['/wallet/fiatAccount']">{{ $t("wallet.fiatAccount") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              test-key="walletHistory"
              class=" appheader-dropdown-item bold "
              :class="{'bg-d':route.path.includes('walletHistory')}"
              @click="() => toRouter('/wallet/walletHistory')"
            >
              <span v-inmodule="['/wallet/walletHistory']">{{ $t("wallet.assetsHistory") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div v-if="userStore.token" class="ah-line" />
      <!-- 个人中心 -->
      <el-dropdown
        v-if="userStore.token"
        id="user-center"
        :teleported="true"
        class="my c-title-hover-primary"
        popper-class="dropdown-no-arrow el-popper-box-p-0"
      >
        <Row class="h-24">
          <IconSvg class="c-t title transition pointer c-hover-primary font-18" name="icon-my" />
        </Row>
        <template #dropdown>
          <el-dropdown-menu class="w-200 p-t-2" x="c">
            <Column x="s" class="w-100b p-lr-20 p-t-18 p-b-10 label">
              <Row v-if="tips" x="s" class="label-lg m-b-2 ellipsis w-160 font-16 bold m-b-6">{{ tips }}</Row>
              <CopyToClip class="bg-lGrey p-tb-1 p-lr-8 radius-12 c-info label-sm" :size="10" :value="userStore?.info?.uid">
                <template #front>
                  <span class="m-r-6">ID {{ userStore?.info?.uid }}</span>
                </template>
              </CopyToClip>
            </Column>
            <el-dropdown-item
              id="to-userOverview"
              x="s"
              class="appheader-dropdown-item p-lr-20"
              :class="{'bg-d':route.path.includes('userOverview')}"
              @click="() => toRouter('/psersonalCenter/userOverview')"
            >
              <span v-inmodule="['/psersonalCenter/userOverview']">{{ $t("wallet.userOverview") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              id="to-accountSecurity"
              x="s"
              class="appheader-dropdown-item p-lr-20"
              :class="{'bg-d':route.path.includes('accountSecurity')}"
              @click="() => toRouter('/psersonalCenter/accountSecurity')"
            >
              <span v-inmodule="['/psersonalCenter/accountSecurity']">{{ $t("wallet.accountSecurity") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              id="to-authentication"
              x="s"
              class="appheader-dropdown-item p-lr-20"
              :class="{'bg-d':route.path.includes('authentication')}"
              @click="() => toRouter('/psersonalCenter/authentication')"
            >
              <span v-inmodule="['/psersonalCenter/authentication']">{{ $t("wallet.authentication") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              id="to-invateRebate"
              x="s"
              class="appheader-dropdown-item p-lr-20"
              :class="{'bg-d':route.path.includes('rebate')}"
              @click="() => toRouter('/rebate')"
            >
              <span v-inmodule="['/rebate']">{{ $t("wallet.invateRebate") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              x="s"
              class="appheader-dropdown-item p-lr-20"
              :class="{'bg-d':route.path.includes('couponCenter')}"
              @click="() => toRouter('/psersonalCenter/couponCenter')"
            >
              <span>{{ $t("couponCenter.couponCenter") }}</span>
            </el-dropdown-item>

            <el-dropdown-item
              v-if="userStore.bdUser == 1"
              id="to-invateRebate"
              x="s"
              class="appheader-dropdown-item p-lr-20"
              :class="{'bg-d':route.path.includes('psersonalCenter/promotionPlan')}"
              @click="() => toRouter('/psersonalCenter/promotionPlan')"
            >
              <span>{{ $t("promotionPlan.promotionPlan") }}</span>
            </el-dropdown-item>
            <Row id="appHeader-logout" x="c" class=" appheader-dropdown-item border-t m-t-10" @click="userStore.logout">
              <span class="c-info">{{ $t("user.logout") }}</span>
            </Row>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- mail -->
      <IconSvg
        v-if="!userStore.token"
        v-to="'/login'"
        class="c-t title m-l-20 font-18 transition pointer c-hover-primary"
        name="icon-message"
        test-key="down-app"
        @click="() => toRouter(`/login?redirect=${route.path}`)"
      />

      <el-dropdown
        v-if="userStore.token"
        id="downloadApp"
        class="c-title-hover-primary"
        :hide-timeout="200"
        :popper-class="`dropdown-no-arrow el-popper-box-p-0 -m-l-20`"
        :teleported="true"
      >
        <Row v-openWin="'/mail'" class="position-r h-24">
          <RedDot v-if="mailStore.unreadCount.allMessages" :position="true" class="position-a -top-5 -right-6 radius-50b" :num="mailStore.unreadCount.allMessages" />
          <IconSvg class="c-t title m-l-20 font-18 transition pointer c-hover-primary" name="icon-message" test-key="down-app" />
        </Row>
        <template #dropdown>
          <Column class="w-330 h-460" y="c" x="c">
            <Row class="w-100b h-10" />
            <el-scrollbar
              class="w-100b"
              :height="376"
            >
              <Column v-if="mailStore.list?.length == 0" class="w-100b p-tb-140" x="c" y="c">
                <img class="w-60 h-68 " src="@/image/mail/no-data.webp">
                <span class="c-info label m-t-16">{{ $t('public.emptyData2') }}</span>
              </Column>
              <Column
                v-for="(item) in mailStore.list"
                :key="item.id"
                class="p-lr-30 p-tb-12 position-r pointer"
                @click="mailRead(item)"
              >
                <span class="label-sm c-info">{{ dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <Row class="label position-r m-tb-3">
                  <div v-if="item.readed == 0" class="position-a w-6 h-6 radius-50b -left-10 top-7 bg-error" />
                  <span class="label w-290 ellipsis-2" :class="item.readed == 0 ? '' : 'c-info'">{{ item.title }}</span>
                </Row>
                <span class="label-sm c-info w-290 ellipsis">{{ item.content }}</span>
              </Column>
            </el-scrollbar>
            <Row class="w-100b h-10" />
            <Row class="h-64 f-size-64 w-100b p-lr-20 border-t" y="c" x="sb">
              <Row>
                <Row class="pointer c-icon-hover-primary m-r-15" @click="() => diaMailSetData.show = true">
                  <IconSvg class="title-sm m-r-6 c-placeholder icon" name="icon-icon_setting" />
                  <span class="label">{{ $t("public.set") }}</span>
                </Row>
                <Row>
                  <Row class="pointer c-icon-hover-primary" @click="readAll">
                    <IconSvg class="title-sm m-r-6 c-placeholder icon" name="icon-yiduicon" />
                    <span class="label">{{ $t("mail.markallRead2") }}</span>
                  </Row>
                </Row>
              </Row>
              <el-button v-openWin="'/mail'" type="primary" class="label" link>{{ $t('home.seeMore') }}</el-button>
            </Row>
          </Column>
        </template>
      </el-dropdown>
      <!-- redirectUrl -->
      <!-- downloadApp -->
      <el-dropdown
        id="downloadApp"
        class="c-title-hover-primary "
        :popper-class="`dropdown-no-arrow el-popper-box-p-0 ${!isHasSetIcon() ? '-m-l-20' : ''}`"
        :teleported="true"
      >
        <Row class="h-24 m-l-20">
          <IconSvg class="c-t title transition pointer c-hover-primary font-18" name="icon-download" test-key="down-app" />
        </Row>
        <template #dropdown>
          <Column class="m-30" y="c" x="c">
            <Row class="w-120 h-120 m-b-10 downloadQrCode-box" x="c" y="c">
              <QrCode id="downloadApp-QrCode" :url="`${h5Url}/goDownload?locale=${configStore.locale}`" :size="100" />
            </Row>
            <span class="font-14">{{ $t('public.scanCodeDownloadApp') }}</span>
          </Column>
        </template>
      </el-dropdown>

      <Row v-if="userStore.token && ['/trade', '/futures'].includes(route.path)" class="h-24 m-l-20">
        <IconSvg class="c-t title transition pointer c-hover-primary font-20 " name="icon-setting" test-key="down-app" @click="() => diaTradeStyleSetData.show = true" />
      </Row>

      <!-- 语言 -->
      <Row class="languag-box h-24 ">
        <Row class="languag-box  h-24   ">
          <el-dropdown
            class="c-title-hover-primary"
            :teleported="true"
            :hide-timeout="200"
            :popper-class="`dropdown-no-arrow -m-l-20`"
          >
            <Row class="m-l-20 language-title pointer font-18" y="c">
              <IconSvg class="title transition c-t c-hover-primary font-18" name="icon-language" />
              <!-- <span class="m-r-4 transition languag-text font-14" :class="languageIsActive ? 'c-primary' : '' " :style="{'white-space':'nowrap'}">{{ configStore.languageName }}</span> -->
            </Row>
            <template #dropdown>
              <Row class="w-140 h-40 p-lr-20 label c-info" y="c">
                {{ $t('public.language') }}
              </Row>
              <el-dropdown-menu class="w-140">
                <el-dropdown-item
                  v-for="languag in configStore.languageMap"
                  :key="languag.value"
                  :class="languag.value == configStore.locale ? 'bg-d' : ''"
                  @click="changeLang(languag)"
                >
                  <Row class="w-100b" x="sb">
                    <span class="m-r-15">{{ languag.label }}</span>
                    <IconSvg v-if="languag.value == configStore.locale" class="font-10 c-primary" name="icon-IconCheck_Menu" />
                  </Row>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </Row>
      </Row>

      <DiaMailSet v-model="diaMailSetData.show" />
      <DiaTradeStyleSet v-model="diaTradeStyleSetData.show" />
    </Row>
  </row></template>

<script setup>

const userStore = useUserStore()
const configStore = useConfigStore()
const tradeStore = useTradeStore()
const futuresStore = useFuturesStore()
const mailStore = useMailStore()
const route = useRoute()

const walletArrow = $ref(false)
const quotesArrow = $ref(false)
const eventsArrow = $ref(false)

const env = $computed(() => {
  return import.meta.env
})

const diaMailSetData = reactive({
  show: false
})

const diaTradeStyleSetData = reactive({
  show: false
})

const keyToPaths = {
  newcomerBenefits: () => '/newcomerBenefits',
  vote: () => '/vote',
  launchpad: () => '/launchpad',
  financial: () => '/financial',
  home: () => '/home',
  // quotes: () => '/quotes',
  c2c: () => '/c2c',
  trade: () => `/trade?symbol=${tradeStore.symbol}`,
  futures: () => `/futures?symbol=${futuresStore.symbol}`,
  registerSendBTC: () => '/registerSendBTC',
  couponCenter: () => '/psersonalCenter/couponCenter',
  promotionPlan: () => '/psersonalCenter/promotionPlan',
  roulette: () => '/christmas',
}

const tips = $computed(() => {
  if (userStore?.info?.nickName) {
    return userStore?.info?.nickName
  }
  if (userStore?.info?.phone) {
    return $f.hidePhone(userStore?.info?.phone)
  }
  if (userStore?.info?.email) {
    return $f.hideEmail(userStore?.info?.email)
  }
})

const isS = $computed(() => {
  if (!['uat', 'production'].includes(envMode)) {
    return true
  } else if (proj === SEPRJ || !proj) {
    return true
  } else {
    return false
  }
})

const isF = $computed(() => {
  if (!['uat', 'production'].includes(envMode)) {
    return true
  } else if (proj === FUPRJ) {
    return true
  } else {
    return false
  }
})

const languageIsActive = $ref(false)

const selectMenu = (key) => {
  keyToPaths[key] && router.push(keyToPaths[key]())
}

onBeforeMount(() => {})

const defaultActive = $computed(() => {
  return Object.keys(keyToPaths).filter(key => {
    const path = keyToPaths[key]()
    return route.path.startsWith(`/${key}`)
  })[0]
})

const obRecharge = () => {
  // const curRoute = walletStore.menuMaps.find((obj) => obj.name === route.name)
  const obj = {
    path: '/home',
    title: 'header.home',
  }
  router.push({
    name: 'Recharge',
    query: { path: JSON.stringify([obj]) }
  })
}

const changeLang = async (languag) => {
  configStore.setLanguage(languag.value)
  userApi.switchLanguage()
}

const readAll = async () => {
  const data = await mailApi.readAll({ })
  mailStore.getUnreadCount()
  mailStore.getList()
}

const mailRead = async (item) => {
  // router.push('/mail')
  window.open(`/mail?msgType=${item.msgType}&expandId=${item.id}`)

  await mailStore.read(item)
  mailStore.getUnreadCount()
  mailStore.getList()
}

const isHasSetIcon = () => {
  return ['/trade', '/futures'].includes(route.path)
}

watch(() => route.path, (newPath, oldPath) => {
}, { immediate: true })

onMounted(() => {
})

</script>

<style lang="scss">
@use "sass:math";
@use "sass:map";
@import '@/sass/var/index.scss';
@media (max-width: 1260px) {
  .AppHeader {
    .languag-text{
      display: none;
    }
  }
}
.ah-line{
    width: 1px;
    height: 20px;
    background: $color-placeholder;
    margin-right: 20px;
  }
.dark{
  .nav-item:hover{
    background-color: var(--el-color-primary);
  }
  .AppHeader{
    border-bottom: 1px solid $color-l-dark !important;
    .el-popper{
      // --el-bg-color-overlay: #fff;
    }
  }
  .ah-line{
    width: 1px;
    height: 20px;
    background: $color-l-dark;
    margin-right: 20px;
  }

}

 .app-language-en_us{
  .nav-item{
    position: relative;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
 }

.downloadQrCode-box{
  background-image: url('image/home/downloadAppQRCode.webp');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.appheader-dropdown-item{
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 0 20px;;
    cursor: pointer;
  }

.AppHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 24px;
  background-color: #fff;
  font-size: 16px;
  border-bottom: 1px solid rgba(218, 221, 234, 0.5) !important;
  z-index: 98;

  &::-webkit-scrollbar{
    display:none
  }
  .c-icon-hover-primary{
    transition: all 0.3s;
    .icon{
      transition: all 0.3s;
    }
    &:hover{
      .icon{
        color: $color-primary !important;
      }
    }
  }
  .c-title-hover-primary:hover{
    .t-d{
      transition: all 0.3s;
      color: $color-primary;
    }
  }
  .logo {
    width: 105px;
    height: 38px;
  }
  .languag-box {
    transition: all 0.1s !important;
    font-size: 16px;
    font-weight: bold;
    .language-title{
      &:hover{
        color: $color-primary;
      }
    }
    &:hover{
      color: $color-primary;
    }
  }
  .nav-menu {
    // width: 700px;
    background-color: transparent !important;
    border-bottom: 0px !important;
    .el-menu--horizontal {
      border:none !important;
      .el-menu-item{
        border-bottom: 0px !important;
        &:hover {
          background-color: transparent !important;
        }
      }
    }
    .nav-item {
      transition: all 0.1s !important;
      background-color: transparent !important;
      border-bottom: 0px !important;
      font-weight: bold !important;
      font-size: 14px;
      height: 56px;
      &:hover {
        background-color: transparent !important;
      }
    }
    .is-active {
      color: var(--el-color-primary) !important;
      border-bottom: 0px !important;
    }
    .is-active:hover,.is-active:focus{
      background-color: transparent !important;
      border-bottom: 0px !important;
    }

    .el-sub-menu__title{
      border-bottom: 0px !important;
    }
  }
  &-dark {
    background-color: #0f0f10 !important;
    color: rgba(255, 255, 255, 0.5) !important;
    .languag-box {
      border-color: rgba(255, 255, 255, 0.2);
    }
    .nav-menu {
      display: block;
      .is-active {
        color: #fff !important;
      }
    }
  }
  .wallet{
      &:hover{
        .wallet-active{
          color: $color-primary !important;
        }
      }
  }

  .wallet-arrow-active{
    transform: rotate(180deg);
    transition: transform .5s;
  }
  .wallet-item-ac{
    background: #F7F9FC;
  }

  .login-hover :hover{
    color: $color-primary;
  }

  .futures-beta{
    position: absolute;
    right:-18px;
    top: 6px;
    width: 28px;
    height: 13px
  }
}
.login-hover :hover{
    color: $color-primary;
  }
  .flow-atow{
    // overflow-x: scroll;
    // overflow-y: visible;
  }
</style>
